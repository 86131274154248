import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import sasuke from '../assets/images/sasuke.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="sasuke287.com" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About</h2>
                </header>
                <p>
                  ドメインを取得してしまったため<br />
                  腐らせるよりはと、とりあえず色々載せているページです。
                </p>
              </div>
              <span className="image">
                <img src={sasuke} alt="アイコン画像" />
              </span>
            </div>
          </section>

          <section id="first" className="main">
            <header className="major">
              <h2>Link</h2>
            </header>
            <p>
              色々リンク
            </p>
            <Link to="https://twitter.com/sasuke_287" target="_blank">
              Twitter @sasuke_287<br />
            </Link>
            <Link to="https://twitter.com/mizuno287" target="_blank">
              Twitter @mizuno287
              <br />
            </Link>
            <Link to="https://steamcommunity.com/profiles/76561198118945309/" target="_blank">
              Steam<br />
            </Link>
            <Link to="https://sasuke287.hatenablog.com/" target="_blank">
              ブログ<br />
            </Link>
          </section>

        </div>
      </Layout>
    )
  }
}

export default Index
