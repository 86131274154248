import React from 'react'

const Header = (props) => (
    <header id="header" className="alt">
        <h1>sasuke287.com</h1>
        <p>sasuke287の置き場</p>
    </header>
)

export default Header
